<template>
  <div class="address container-fluid" :class="{'pdf': binding === 'pdf'}">
    <form name="address" class="row" @submit.prevent>
      <div class="col-xs-12 col-sm-10 col-sm-offset-1">
        <div class="row">
          <div class="col-xs-6 col-sm-4 form-group">
            <label for="name">{{$t('common.label.lastName')}}</label>
            <input class="form-control" :class="{'error': validateList.indexOf('lastName') > -1}"
                   id="name" type="text" :placeholder="$t('common.label.lastName')"
                   v-model="address.lastName" @blur="blurItem($event, 'lastName')">
          </div>
          <div class="col-xs-6 col-sm-4">
            <label for="first-name">{{$t('common.label.firstName')}}</label>
            <p>
              <input class="form-control" :class="{'error': validateList.indexOf('firstName') > -1}"
                     id="first-name" type="text" :placeholder="$t('common.label.firstName')"
                     v-model="address.firstName" @blur="blurItem($event, 'firstName')">
            </p>
          </div>
          <div class="col-xs-12 col-sm-4" v-if="binding === 'pdf'">
            <label>{{$t('common.label.email')}}</label>
            <p>
              <input class="form-control" :class="{'error': validateList.indexOf('email') > -1}"
                     type="text" placeholder="$t('common.label.email')"
                     v-model="address.email" @blur="blurItem($event, 'email')">
            </p>
          </div>
        </div>
        <template v-if="binding !== 'pdf'">
          <div class="row">
            <div class="col-xs-12 col-md-8 form-group">
              <label for="address-1">{{$t('common.label.line1')}}</label>
              <input class="form-control" :class="{'error': validateList.indexOf('line1') > -1}"
                     id="address-1" type="text" :placeholder="$t('address.placeholder.correct')"
                     v-model="address.line1" @blur="blurItem($event, 'line1')">
            </div>
            <div class="col-md-4 hidden-xs hidden-sm address-tip">
              {{$t('address.tip.line1')}}
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-8 form-group">
              <label for="address-2">{{$t('common.label.line2')}}</label>
              <input class="form-control"
                     id="address-2" type="text" :placeholder="$t('address.placeholder.detail')"
                     v-model="address.line2">
            </div>
            <div class="col-md-4 hidden-xs hidden-sm">
              {{$t('address.tip.line2')}}
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-4 form-group">
              <label for="city" class="text-ellipsis">{{$t('common.label.city')}}</label>
              <input class="form-control" :class="{'error': validateList.indexOf('city') > -1}"
                     type="text" id="city" :placeholder="$t('address.placeholder.correct')"
                     v-model="address.city" @blur="blurItem($event, 'city')">
            </div>
            <div class="col-xs-6 col-sm-4 form-group">
              <label for="province" class="text-ellipsis">{{$t('common.label.state')}}</label>
              <select class="form-control" :class="{'error': validateList.indexOf('state') > -1}"
                      name="province" id="province"
                      v-model="address.state" @blur="blurItem($event, 'state')">
                <option :value="item.value" v-for="(item, index) in cities" :key="index">
                  {{item.label}}
                </option>
              </select>
            </div>
            <div class="col-xs-6 col-sm-4 form-group">
              <label for="region" class="text-ellipsis">{{$t('common.label.country')}}</label>
              <select class="form-control" :class="{'error': validateList.indexOf('countryCode') > -1}"
                      name="province" id="region"
                      v-model="address.countryCode" @blur="blurItem($event, 'countryCode')">
                <option :value="item.value" v-for="(item, index) in country" :key="index">
                  {{item.label}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-4 form-group">
              <label for="postal-code">{{$t('common.label.postal')}}</label>
              <input class="form-control" :class="{'error': validateList.indexOf('postalCode') > -1}"
                     type="text" id="postal-code"
                     v-model="address.postalCode" @blur="blurItem($event, 'postalCode')">
            </div>
            <div class="col-xs-12 col-md-4 form-group">
              <label for="phone">{{$t('common.label.telephone')}}</label>
              <input class="form-control" :class="{'error': validateList.indexOf('phone') > -1}"
                     :placeholder="$t('common.label.telephone')" type="text" id="phone"
                     v-model="address.phone" @blur="blurItem($event, 'phone')">
            </div>
            <div class="col-xs-12 col-md-4 form-group">
              <label for="email">{{$t('common.label.email')}}</label>
              <input class="form-control" :class="{'error': validateList.indexOf('email') > -1}"
                     type="text" id="email"
                     v-model="address.email" @blur="blurItem($event, 'email')">
            </div>
          </div>
        </template>
      </div>
    </form>
    <modal :show="postAddress" @close="postAddress = false">
      <confirm :address="address" :binding="binding" slot="body"></confirm>
      <div class="footer row" slot="footer">
        <button class="col-xs-6 text-red" @click="postAddress = false">{{$t('common.button.cancel')}}</button>
        <button class="col-xs-6 text-primary" @click="goTrade">
          <b>{{$t('common.button.ok')}}</b>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import api from 'src/api/'
import Cookies from 'js-cookie'
import Confirm from './AddressComfirm'
import { find, orderBy, filter } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import region from '../../../../static/json/region.json'
import countries from '../../../../static/json/country.json'

const addressOptions = {
  lastName: '',
  firstName: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  countryCode: '',
  country: '',
  postalCode: '',
  phone: '',
  email: ''
}
export default {
  name: 'address',
  components: { Confirm },
  data () {
    return {
      postAddress: false, // 是否可以进行提交
      address: Object.assign({}, addressOptions),
      binding: 'hardback',
      validateList: [],
      country: []
    }
  },
  computed: {
    ...mapGetters([
      'locale'
    ]),
    cities () {
      const value = this.address.countryCode
      if (value) {
        return find(region, { value }).region
      } else {
        return []
      }
    }
  },
  created () {
    const { binding, addressId } = this.$route.query
    this.binding = binding || Cookies.getJSON('order') || 'hardback'
    if (this.binding === 'pdf') {
      this.address = {
        lastName: '',
        firstName: '',
        email: ''
      }
    }
    region.map((item) => {
      this.country.push({
        value: item.value,
        label: item.label
      })
    })
    if (addressId) {
      this.fetchAddress(addressId)
      return
    }
    this.fetchAddresses().then(data => {
    })
  },
  watch: {
    'address.countryCode' (val) {
      this.address.country = find(countries, { alias: val }).label
      if (val !== this.locale) {
        this.setLocale(val)
      }
    },
    'address.state' (val) { // 香港，澳门地区的城市默认为区
      if (['HK', 'MO'].includes(this.address.countryCode)) {
        this.address.city = val
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'trade' && !this.postAddress) {
      this.validateData().then(data => {
        if (!data) {
          this.$toast({
            message: this.$t('address.toast.checkAddressInfo')
          })
          return
        }
        this.postAddress = true
      })
    } else {
      next()
    }
  },
  methods: {
    ...mapActions([
      'setLocale'
    ]),
    fetchAddresses () {
      return api.fetchOrderAddresses('delivery').then(data => {
        if (!data) {
          this.$toast({
            message: this.$t('address.toast.addressLoadFailed')
          })
          return Promise.resolve(false)
        }

        const addresses = filter(data, { countryCode: this.locale })
        if (addresses.length) {
          let address = orderBy(addresses, ['updatedAt'], ['desc'])[0]
          if (this.binding !== 'pdf') {
            delete address.atype
            delete address.default
            delete address.deleted
            delete address.editable
          } else {
            address = {
              addressId: address.addressId,
              email: address.email,
              firstName: address.firstName,
              lastName: address.lastName
            }
          }
          this.address = Object.assign({}, this.address, address)
          console.log(this.address)
        } else {
          this.address.countryCode = this.locale
        }
        return Promise.resolve(data)
      })
    },
    fetchAddress (addressId) {
      return api.fetchAddress(addressId).then((data) => {
        if (this.binding !== 'pdf') {
          delete data.atype
          delete data.default
          delete data.deleted
          delete data.editable
        } else {
          data = {
            addressId: data.addressId,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName
          }
        }
        this.address = Object.assign({}, data)
      })
    },
    blurItem (event, name) {
      this.validateData(name)
    },
    validateData (name) {
      if (name) { // 验证地址信息
        if (!this.address[name]) {
          if (this.validateList.indexOf(name) < 0) {
            this.validateList.push(name)
          }
          return
        }
        if (name === 'phone' && (this.address[name] + '').length < 6) {
          if (this.validateList.indexOf(name) < 0) {
            this.validateList.push(name)
          }
          return
        }
        const oIndex = this.validateList.indexOf(name)
        this.validateList.splice(oIndex, 1)
        return
      }
      let state = true
      this.validateList = []
      Object.keys(this.address).map((item, index) => {
        if (item !== 'line2' && !this.address[item]) { // 验证是否为空
          this.validateList.push(item)
          state = false
        }
      })
      return Promise.resolve(state)
    },
    async goTrade () {
      let { query, params } = this.$route
      let addressId = this.address.addressId
      if (addressId) { // 当前存在历史收件地址，更新地址
        this.updateAddress(addressId, this.address)
      } else {
        addressId = await this.createAddress(this.address)
      }
      query = Object.assign(query, { addressId })
      this.$router.push({ name: 'trade', query, params })
      this.postAddress = false
    },
    createAddress (address) {
      address = Object.assign({}, addressOptions, address, { type: 'delivery' })
      return api.createAddress(address).then(data => {
        if (!data || data.errors) {
          const errorCode = data.errors ? data.errors.code : ''
          const message = errorCode ? this.$t('address.toast.addressCreateFailed') + errorCode : this.$t('address.toast.addressCreateFailed')
          this.$toast({
            message,
            position: 'bottom'
          })
          return
        }
        return data.addressId
      })
    },
    updateAddress (addressId, address) {
      try {
        address = Object.assign({}, addressOptions, address, { type: 'delivery' })
      } catch (err) {
        console.log(err)
      }

      api.updateAddress(addressId, address).then(data => {
        if (!data || data.errors) {
          const errorCode = data.errors ? data.errors.code : ''
          const message = errorCode ? this.$t('address.toast.addressCreateFailed') + errorCode : '收件地址更新失敗' + this.$t('address.toast.addressCreateFailed')
          this.$toast({
            message,
            position: 'bottom'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '../../../styles/layout/Address';
</style>
